<template>
    <el-dialog title="审核" :visible.sync="audit.visible" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit" >
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="报溢单ID">{{form.baoYiDanID}}</el-form-item>
            <el-form-item label="报溢时间">{{form.baoYiSJ}}</el-form-item>
            <el-form-item label="报溢商品种数">{{form.baoYiShangPinZhongShu}}</el-form-item>
            <el-form-item label="报溢总金额">{{form.baoYiZongJinE}}</el-form-item>
            <el-form-item label="报溢人">{{form.baoYiRen}}</el-form-item>
            <el-form-item label="报溢人ID">{{form.baoYiRenID}}</el-form-item>
            <el-form-item label="备注">{{form.beiZhu}}</el-form-item>
            <x-divider title="审核信息"></x-divider>
            <el-form-item label="申请人">{{form.applicant}}</el-form-item>
            <el-form-item label="申请时间" class="w66">{{form.applyDate}}</el-form-item>
            <el-form-item label="审核意见" class="line">
                <el-input v-model="form.auditMind" type="textarea" maxlength="100" show-word-limit />
            </el-form-item>
        </el-form>
        <x-divider title="报溢信息详情"></x-divider>
        <el-table :data="form.baoYiXinXiXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="baoYiCangKuID" label="报溢仓库ID" />
            <el-table-column width="150" prop="baoYiCangKuMC" label="报溢仓库名称" />
            <el-table-column width="150" prop="baoYiShangPinID" label="报溢商品ID" />
            <el-table-column width="150" prop="baoYiShangPinMC" label="报溢商品名称" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="chengBenJia" label="成本价" />
            <el-table-column width="150" prop="dangQianKuCunSL" label="当前库存数量" />
            <el-table-column width="150" prop="baoYiSL" label="报溢数量" />
            <el-table-column width="150" prop="baoYiJinE" label="报溢金额" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="pass" icon="el-icon-check">通 过</el-button>
            <el-button type="danger" @click="reject" icon="el-icon-close">驳 回</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {getAudit, audit} from "@/service/kcgl/BaoYiXX";
    import {ruleBuilder} from '@/util/validate';
    import XAuditBase from "@/components/x/XAuditBase";

    export default {
        mixins: [XAuditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                auditMind: []
            };
            this.getAuditService = getAudit;
            this.auditService = audit;
            this.defaultForm = {
                id: null,
                baoYiDanID: "", // 报溢单ID
                baoYiSJ: "", // 报溢时间
                baoYiShangPinZhongShu: "", // 报溢商品种数
                baoYiZongJinE: "", // 报溢总金额
                baoYiRen: "", // 报溢人
                baoYiRenID: "", // 报溢人ID
                beiZhu: "", // 备注
                applicant: "", // 申请人
                applyDate: "", // 申请时间
                auditMind: "", // 审核意见
                auditStatus: "", // 审核状态
                baoYiXinXiXiangQings: [],
            };
        }
    }
</script>

<style scoped>

</style>