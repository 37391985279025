<script>
    import {deepCopy, deepMerge} from '@/util/objects';
    import {DICT_AUDIT_STATUS} from "@/util/constant"
    import bjggs from "@/util/bjggs";

    export default {
        watch: {
            "audit.visible": {
                handler: function (v, o) {
                    v && this.open();
                },
                immediate: true
            }
        },
        data() {
            return {
                form: deepCopy(this.defaultForm)
            }
        },
        props: {
            audit: {
                type: Object,
                required: true,
                validator: (value) => {
                    return ['visible', 'id'].every((item) => value.hasOwnProperty(item))
                }
            }
        },
        methods: {
            _clearValidate() {
                this.$nextTick(() => {
                    this.$refs.form.clearValidate();
                });
            },
            open() {
                return this.getAuditService(this.audit.id).then((response) => {
                    this.form = deepMerge(this.defaultForm, response.data);
                    //清空校验
                    this._clearValidate();
                    return response;
                });
            },
            async auditSave() {
                await this.$refs.form.validate();
                const response = await this.auditService(this.form);//审核方法
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.audit.visible = false;
            },
            pass() {
                this.form.auditStatus = DICT_AUDIT_STATUS.PASS.key;
                this.auditSave();
            },
            reject() {
                this.form.auditStatus = DICT_AUDIT_STATUS.BACK.key;
                this.auditSave();
            },
            cancel() {
                //关闭弹出框
                this.audit.visible = false;
            },
            //是否开启同步
            isSyncOpen(){
                return bjggs.IS_SYNC_OPEN;
            }
        }
    }
</script>
